import { Table, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { json } from 'stream/consumers';


function App() {
  const [dados, setDados] = useState([]);
  const [anos, setAnos] = useState<String[]>([])
  const [turmas, setTurmas] = useState<String[]>([])
  const [resultados, setResultados] = useState(25)
  const [paginas, setPaginas] = useState(1)
  const [rows, setRows] = useState(0)
  const [paginasRows, setPaginasRows] = useState<any[]>([])
  const [principalValor, setPrincipalValor] = useState('')
  const [principalCampo, setPrincipalCampo] = useState('')

  var requisicaoJSON = {
    principal_campo: principalCampo,
    principal_valor: principalValor,
    ordem_campo: "nome",
    ordem_ordem: "asc",
    offset: resultados,
    pagina: paginas
  };

  const requisiaoPesquisaGeral = () => {
    axios.post("https://aaafmusp.com/api/listagem2", requisicaoJSON)
      .then((response) => {
        const resposta = response.data.resposta

        const rowa = response.data.n_linhas

        var rw

        if (rowa) {
          rw = Number(JSON.stringify(rowa).replace(/\D/g, ""))
        } else {
          rw = 1
        }

        const rw_f: any[] = createArrayWithIncrements(rw, requisicaoJSON.offset)

        setPaginasRows(rw_f)

        setDados(resposta)
        console.log(resposta)
      })
      .catch((error) => console.error("Erro ao obter os dados:", error));
  }

  const requisiaoPesquisaNome = () => {
    axios.post("https://aaafmusp.com/api/listagem2", requisicaoJSON)
      .then((response) => {
        const resposta = response.data.resposta

        const rowa = response.data.n_linhas

        const rw = Number(JSON.stringify(rowa).replace(/\D/g, ""))

        const rw_f: any[] = createArrayWithIncrements(rw, requisicaoJSON.offset)

        setPaginasRows(rw_f)

        setDados(resposta)
      })
      .catch((error) => console.error("Erro ao obter os dados:", error));
  }

  const handleAnoChange = (event: any) => {
    setPrincipalCampo("ano")
    setPrincipalValor(event.target.value === "Todos" ? "" : event.target.value)
  }

  const handleTurmaChange = (event: any) => {
    setPrincipalCampo("turma")
    setPrincipalValor(event.target.value === "Todos" ? "" : event.target.value)
  }

  const handleNomeChange = (event: any) => {
    setPrincipalCampo("nome")
    setPrincipalValor(event.target.value)
  }

  const handlePaginasChange = (e: any) => {
    setResultados(e.target.value)

    requisicaoJSON.offset = e.target.value

    requisiaoPesquisaGeral()
  }

  const handlePaginaChange = (e: any) => {
    setPaginas(parseInt(e.target.text))

    requisicaoJSON.pagina = parseInt(e.target.text)

    requisiaoPesquisaGeral()
  }

  function createArrayWithIncrements(targetValue: number, incremento: any) {
    const increment = Number(incremento)
    let resultArray = [];
    for (let i = increment; i <= targetValue; i += increment) {
      resultArray.push(i);
    }
    return resultArray;
  }

  useEffect(() => {
    axios.post("https://aaafmusp.com/api/listagem2", requisicaoJSON)
      .then((response) => {
        const resposta = response.data.resposta
        const rowa = response.data.n_linhas

        const rw = Number(JSON.stringify(rowa).replace(/\D/g, ""))

        const rw_f: any[] = createArrayWithIncrements(rw, resultados)

        var [anosUnicos, turmasUnicas]: any[] = [[], []]

        for (let i in resposta) {
          if (!(anosUnicos.includes(resposta[i].ano))) anosUnicos.push(resposta[i].ano)
          if (!(turmasUnicas.includes(resposta[i].turma))) turmasUnicas.push(resposta[i].turma)
        }
        const turmas_resp = response.data.turmas

        const turmas_obj = []

        for (let i in turmas_resp) {
          turmas_obj.push(turmas_resp[i].turma)
        }

        setTurmas(turmas_obj)

        const anos_resp = response.data.anos

        const anos_obj = []

        for (let i in anos_resp) {
          anos_obj.push(anos_resp[i].ano)
        }

        setAnos(anos_obj)

        setDados(resposta)

        setRows(rw)

        setPaginasRows(rw_f)
      })
      .catch((error) => console.error("Erro ao obter os dados:", error));
  }, []);

  useEffect(() => {
    requisiaoPesquisaGeral()
  }, [principalValor])

  return (
    <div className="App p-3 d-flex justify-content-center">
      <div className="w-100 p-3 mh-50 d-flex flex-column">
        <img src="http://aaafmusp.com.br/images/logoaaafmusp.jpg" style={{ justifySelf: "center", alignSelf: "center" }}></img>
        <div className="overflow-auto" style={{ maxHeight: "700px" }}>
          <Table bordered striped>
            <thead>
              <tr>
                <th className="w-50">
                  Aluno
                  <Input
                    name="ano"
                    type="text"
                    onChange={handleNomeChange}
                  >
                  </Input>
                </th>
                <th>
                  Ano de formatura
                  <Input
                    name="ano"
                    type="select"
                    onChange={handleAnoChange}
                  >
                    <option>
                      Todos
                    </option>
                    {anos && anos.map((item) => (
                      <option>
                        {item}
                      </option>
                    ))}
                  </Input>
                </th>
                <th>
                  Turma
                  <Input
                    name="turma"
                    type="select"
                    onChange={handleTurmaChange}
                  >
                    <option>
                      Todos
                    </option>
                    {turmas && turmas.map((item) => (
                      <option>
                        {item}
                      </option>
                    ))}
                  </Input>
                </th>
              </tr>
            </thead>
            <tbody>
              {dados.map((item: any, index) => (
                <tr key={index}>
                  <td>{item.nome}</td>
                  <td>{item.ano}</td>
                  <td>{item.turma}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Input
            name="ano"
            type="select"
            onChange={handlePaginasChange}
            style={{ width: "10%", height: "45px" }}
          >
            <option>
              25
            </option>
            <option>
              50
            </option>
            <option>
              100
            </option>
            <option>
              250
            </option>
            <option>
              500
            </option>
          </Input>
          <nav aria-label="Page navigation example">
            <ul className="pagination" style={{ overflow: "auto", maxWidth: "500px" }}>
              {paginasRows.map((x, index) => (
                <li className="page-item" style={{ cursor: "pointer" }}><a className="page-link" onClick={handlePaginaChange}>{index + 1}</a></li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default App;
